/**
 * Barcode object JS used to parse, validate and create a barcode object from a scan.
 * Created by andrew.stalker on 11/01/2019.
 */

/* globals isAlreadyScanned, VesselBarcodeType, dataToBarcode, getProgramVesselBarcodeType, padNumber, isBarcodeValid, validateCaskDetails, barcodeToData,
   prefixWoodLabel, showAlert, trimPrefixFromWoodLabel, hideDoubleScanError, formatWoodDisplayLabel, getProgramReaderIgnored, transformField, saveGenericTransaction */

/**
 * @typedef {Object} barcodeFormat
 * @property {String} formatCode - Unique code of the format
 * @property {String} barcodeType - CASK or TUN, for vessel scanning.
 * @property {String} expression - Regular expression to match the barcode scanned
 * @property {String} expressionGroups - data groups to split the input into
 * @property {Boolean} scotchVariant - True if SWA style label. False usually indicates a CaskId
 * @property {String} prefixData - Prefixing data, for CaskIds
 * @property {Number} paddedLength - Length an ID should be when shorthand entered. For CaskId.
 * @property {String} reader - 1D or RF, indicating which type of reader would read a string to match the format.
 */

// todo refactor vesselBarcodeType to be named barcodeFormatType as that is what it actually is.
// todo change type to be some sort of barcodeObjectResult as this is a wider used value.
// (easier to do this if the old labels are removed from the system).

/**
 * @typedef {Object} barcodeObject
 * @property {String} [barcode] An SWA style barcode if scanned or mapped from caskID
 * @property {String} [caskId] A vessel identifier barcode, for wood tracking or when no SWA use at site.
 * @property {boolean} passed Indicates if the barcode was created and passed all validation
 * @property {string} displayCode The string to be displayed on screen when this barcode type is scanned.
 * @property {string} type The type of barcode that has been created. Updates to invalid types when failing validation.
 * @property {Object} swa An SWA object of SWA information if it can be deciphered from barcode when created.
 * @property {Object} tun A tun object of tunNumber, location, and warehouse.
 * @property {Object} pallet A pallet object consisting of the full label and number if the label scanned was a pallet.
 * @property {String} entryType Values of S or M. This indicates if the barcode was scanned or Manually typed in.
 * @property {VesselBarcodeType.string} vesselBarcodeType String type of vessel label, from constant VesselBarcodeType
 */

// todo should we be able to create a barcode object from SWA only (using the manual popup).

/**
 * @description Using barcode validation, a barcode object will be returned with clear separation between SWA and WTL and if it passed validation.
 * Will also alert to the user if a) Already Scanned, b) Last scanned if set, and c) Invalid.
 * @param {string} scannedCode  The scanned code
 * @param {string} [scanInputField]  DOM element id the scan came from E.G. "scanInput"
 * @param {string|Number} [validationField]  variable that may be required for additional validation
 * @param {VesselBarcodeType.string | ''} [requiredBarcodeType] An optional type of barcode that the scanned code must be
 * @returns {{}} Barcode Object
 */
function createBarcodeObject(scannedCode, scanInputField, validationField, requiredBarcodeType) {
	"use strict";
	var barcodeObject,
		swaObject = {
			make: '',
			fillYear: 0,
			rotation: 0,
			caskNumber: 0,
			fillLocation: 'ZZ'
		},
		tun = {},
		pallet = {label: "", palletNo: 0},
		barcode = "",
		caskId = "",
		passed = false,
		displayCode = "",
		barcodeType,
		vesselBarcodeType,
		entryType = "",
		programVesselBarcodeType = getProgramVesselBarcodeType(),
		preChecksPassed = true,
		readerIgnored = getProgramReaderIgnored(),
		originalBarcodeType = "",
		prefix = localStorage.getItem("settings/scanPrefix"),
		customFormatsInUse = (localStorage.getVariable("settings/useCustomBarcodeFormats") === "true");

	if (prefix !== "") {
		// check / remove prefix.
		if (scannedCode.substr(0, prefix.length) === prefix) {
			entryType = "S";
			scannedCode = scannedCode.substr(prefix.length);
		} else {
			entryType = "M";
		}
	}

	// TODO below needs to be updated to use the new parser code. This will require callback handling.
	// This will best be implemented if SWA and WTL are fully deprecated.
	// In addition, CASK type should be split to CASK and CASKID, to split between the two.
	// Would need to check how cask is currently used in program parameters.
	// TODO using shared code would need to filter reader types out first... So the code would need to receive a list of formats allowed.
	if (customFormatsInUse) {
		//Ignore the old isBarcodeValid and switches.
		var barcodeFormats = localStorage.getObject("data/barcodeFormats");

		//Search for matching regular expression, identifying the label scanned.
		for (var ft=0; ft<barcodeFormats.length; ft++) {
			var barcodeFormat = barcodeFormats[ft],
			regExpress = new RegExp(barcodeFormat.expression),
			formatFields = barcodeFormat.fields,
			regExResult = regExpress.exec(scannedCode);
            // regExResult can be two possible things now, null if it failed.
			// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
			// An array if the regEx matched. [0] in the array is the complete string captured to make the match
			// [1], ...[n] in the array are capture groups defined by the formatFields within setup
			// We can then use the array to pull out the properties expected in the format.
			if (regExResult) {
				//Result was not null so the expression must have matched the string.
				var capturedFields = {};

				// PRE CHECKS
				// 1. Allowed Reader? To filter out inputs read by a reader that is not allowed in the current program
				// If the program should ignore a reader and the Barcode Format has the reader type to match then we ignore it.
				if (readerIgnored !== "" && readerIgnored === barcodeFormat.reader) {
					passed = false;
					preChecksPassed = false;
					barcodeType = "INVALID-READER";
					break;
				}
				// Todo we could add a pre check for the correct type. If not then break, but set a flag for an error
				// Then replace the post check for the correct type.

				// pull the capture groups into capture fields named by the barcode format
				// Note that we ignore a 'FILLER' field as this was previously used to ignore fields defined in a format.
				for (var r=1; r<regExResult.length; r++) {
					var fieldName = formatFields[r-1].name;
					if (formatFields[r-1] && fieldName.toUpperCase() !== "FILLER") {
						if (typeof (capturedFields[fieldName]) === 'undefined') {
							capturedFields[fieldName] = transformField(regExResult[r], formatFields[r-1].transformer, formatFields[r-1].transformerValue);
						} else {
							capturedFields[fieldName] += transformField(regExResult[r], formatFields[r-1].transformer, formatFields[r-1].transformerValue);
						}
					}
				}

				// Switch on the barcodeType to then use the captured fields as appropriate
				switch (barcodeFormat.type.toUpperCase()) {
					case "SWA":
						barcodeType = "CUSTOM";
						vesselBarcodeType = VesselBarcodeType.scotch;

						// Find the fields in the capture groups that match and update the SWA object
							for (var prop in swaObject) {
								if (swaObject.hasOwnProperty(prop)) {
									if (capturedFields.hasOwnProperty(prop)) {
										swaObject[prop] = capturedFields[prop];
									}
								}
							}
						// Todo we really should refactor this to just set the barcode to whatever was scanned.
						// however at present we need to glue it into the classic mapping so that double scans can be checked correctly.
						// Before that can happen, the backend must not use the barcode to parse SWA detail, and instead this should be set in the transaction sent to the server.
						// Create the barcode scanned from this. (not the original barcode but one glued together to match the standard SWA Mapping).
						barcode = dataToBarcode(swaObject.make, swaObject.fillYear, swaObject.rotation, swaObject.caskNumber, swaObject.fillLocation);

						displayCode = scannedCode; //Set displayCode
							passed = !isAlreadyScanned(barcode,"SWA",scanInputField,validationField); //Check for double scan / already scanned.

						break;
					case "CASKID":
						barcodeType = "CUSTOMID";
						vesselBarcodeType = VesselBarcodeType.caskId;

						// No matter what, the prefix field is added to the cask ID. The caskId is the only thing displayed.
						// This means a prefix is never displayed.
						// TODO if we made it like pallets, we could conditionally show the prefix if captured!
						displayCode = capturedFields.caskId;
						caskId = barcodeFormat.prefix + capturedFields.caskId;
						// This function shows alerts, which block the DOM and then could be overwritten.
						passed = !isAlreadyScanned(caskId,"WTL",scanInputField,validationField); // Check for double scan / already scanned.
						break;
					case "TUN":
						barcodeType = "TUN";
						vesselBarcodeType = VesselBarcodeType.tun;
						tun = {location: capturedFields.location, warehouse: capturedFields.warehouse, tunNumber: capturedFields.tunNumber};
						// WARNING there is no check to see if a tun has already been scanned here, since tuns are only scanned in allocations.
						// The allocation check does the checking to see if a tun has been scanned.
						// If this needs to be checked here, movement casks which is strings, would need to be changed to objects.
						// I.E. add scanned code to movement casks, then add a new check in isAlreadyScanner for tuns.
						// tun scanning page or transaction save would also need to save relevant details to array.
						// todo also there is no display code set here.
						barcode = scannedCode;
						passed = true; // change to return results of scanned already.
						break;
					case "PALLET":
						barcodeType = "PALLET";
						// add palletPrefix to fields. if captureFields has own property of the prefix then glue.
						// this is to ensure the pallet becomes unique if it has prefixes and a cask ID does not.
						// Only for display purposes. As a pallet is a number, only the palletNo is ever needed in API.
						if (capturedFields.hasOwnProperty("palletPrefix")) {
							displayCode = capturedFields.palletPrefix + "" + capturedFields.palletNo;
						} else {
							displayCode = capturedFields.palletNo;
						}
						vesselBarcodeType = VesselBarcodeType.pallet;
						// Also cast the palletNo to a number by *1.
						pallet = {label: displayCode, palletNo: capturedFields.palletNo*1};
						passed = !isAlreadyScanned(pallet.label, "PALLET", scanInputField, validationField);
						break;
					default:
						break;
				}
			break; //break from the barcode format loop as we have matched and done all we should.
			}
		}
		//SHOULD THE POST CHECKS BE HERE?
		//passed will remain false if it was never set above.

	} else {
		// Use old standard methods to validate the barcode
		barcodeType = isBarcodeValid(scannedCode.toUpperCase(), scanInputField, validationField);
		// Switch to set the vesselBarcodeType from legacy call above.
		switch(barcodeType) {
			case "WTL":
			case "WTLS":
			case "WTLL":
				// These are all cask ID label types.
				vesselBarcodeType = VesselBarcodeType.caskId;
				break;
			case "SWA":
				// These are all SWA type
				vesselBarcodeType = VesselBarcodeType.scotch;
				break;
		}
	}

	//To check a format was found - check vesselBarcodeType SET.
	//If the pre checks failed then they will have set an error to handle.
	if (preChecksPassed && vesselBarcodeType) {
		// POST PARSE CHECKS - These are checks to see if the barcode scanned is appropriate.
		// By this point the new code has a vesselBarcodeType to identify vessel label types.
		// 1. Allowed in field? to filter allowed barcode type if the option was set, in the function call (input parameter).
		if(typeof requiredBarcodeType !== "undefined") {
			// The type was passed in somehow
			if (requiredBarcodeType !== null ) {
				// A value has been passed.
				if (vesselBarcodeType !== requiredBarcodeType) {
					// A barcode object has been created from the wrong type of barcode. Error.
					passed = false;
					barcodeType = "INVALID-FIELD";
				}
			}
		}

		// 2. Allowed in Program? to filter allowed barcode types, which will be based on program parameter upon entry.
		// If the program cannot scan all, or the barcode doesn't match what the program requires then reject it.
		// Changed to search the programVesselBarcodeType as if it is a list and search the list for a match.
		if (programVesselBarcodeType !=="ALL" && programVesselBarcodeType.search(vesselBarcodeType) < 0 ) {
			originalBarcodeType = barcodeType; // todo what is the originalBarcodeType for?
			barcodeType = "INVALID-PROGRAM";
		}
	}

	// Handling for standard coded types. Catches doubles and errors from custom barcode types. Shows Alerts.
	switch (barcodeType) {
		case "CUSTOM":
			//By here passed might be false due to double scan or already scanned so && with SWA validation
			// todo note that the make is trimmed as elsewhere it might be padded to match the barcode length :/
			passed = passed && validateCaskDetails(swaObject.make.trim(),swaObject.fillYear,swaObject.rotation,swaObject.caskNumber,swaObject.fillLocation);
			break;
		case "CUSTOMID":
		case "TUN":
		case "PALLET":
			// Don't assign anything as passed set by isAlreadyScanned function
			break;
		case "SWA":
			barcode = scannedCode;
			displayCode = scannedCode;
			swaObject = barcodeToData(scannedCode);
			passed = validateCaskDetails(swaObject.make.trim(),swaObject.fillYear,swaObject.rotation,swaObject.caskNumber,swaObject.fillLocation);
			break;
		case "WTL":
			caskId = scannedCode;
			passed = true;
			displayCode = trimPrefixFromWoodLabel(scannedCode);
			break;
		case "WTLS":
			caskId = prefixWoodLabel(scannedCode);
			passed = true;
			displayCode = formatWoodDisplayLabel(scannedCode);
			break;
		case "WTLL":
			caskId = scannedCode.substring(0, localStorage.getItem("settings/woodTrackingMap").length);
			passed = true;
			displayCode = trimPrefixFromWoodLabel(caskId);
			break;
		case "SCANNED":
			passed = false;
			showAlert("This cask has already been scanned", {focus: scanInputField});
			break;
		case "DOUBLE":
			passed = false;
			if(!hideDoubleScanError) {
				showAlert("This cask has already been scanned", {focus: scanInputField});
			}
			break;
		case "INVALID-PROGRAM":
			passed = false;
			showAlert("This barcode type cannot be scanned in this program", {focus: scanInputField});
			break;
		case "INVALID-FIELD":
			passed = false; //This is a special case that has to be handled by the program fields specifically. No Alert.
			break;
		case "INVALID-READER":
			passed = false; //This is a special case that has to be handled by the program fields specifically. No Alert.
			break;
		default:
			passed = false;
			showAlert("Invalid Barcode Scanned", {focus: scanInputField});
	}

	barcodeObject = {
		barcode: barcode,
		caskId: caskId,
		passed: passed,
		displayCode: displayCode,
		type: barcodeType,
		swa: swaObject,
		tun: tun,
		pallet: pallet,
		entryType: entryType,
		vesselBarcodeType: vesselBarcodeType
	};

	return barcodeObject;
}

/**
 *
 */
function createManualBarcodeObject(inputMake, inputFillYear, inputRotation, inputCaskNumber, inputFillLocation) {
	"use strict";

	var barcodeObject,
		barcode = "",
		displayCode = "",
		passed = false,
		swaObject = {
		make: inputMake,
		fillYear: inputFillYear,
		rotation: inputRotation,
		caskNumber: inputCaskNumber,
		fillLocation: inputFillLocation
	};

	/* Check the manual fields are all valid. */
	passed = validateCaskDetails(inputMake, inputFillYear, inputRotation, inputCaskNumber, inputFillLocation);

	/* Fake the barcode string. */
	barcode = dataToBarcode(inputMake, inputFillYear, inputRotation, inputCaskNumber, inputFillLocation);
	displayCode = barcode; /* Set the display code to match the created barcode - consistent with scanning. */

	/* Check the cask has not already been scanned. */
	passed = passed && !isAlreadyScanned(barcode,"SWA","",""); //Check for double scan / already scanned.

	// TODO do we need to check the program allows the barcode. Unlikely if we are only getting the button if allowed.

	/* Create a barcode object in the same structure as the scanned one. Some fields just get the default values */
	// todo this could be wrapped away like transaction creation, to give a more consistent object for both functions.
	barcodeObject = {
		barcode: barcode,
		caskId: '',
		passed: passed,
		displayCode: displayCode,
		type: "SWA",
		swa: swaObject,
		tun: {},
		pallet: {label: "", palletNo: 0},
		entryType: 'M',
		vesselBarcodeType: VesselBarcodeType.scotch
	};

	return barcodeObject;
}

